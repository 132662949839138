<template>
  <div>
    <!-- 公用弹窗(公司荣誉) -->
    <div class="popbox" v-if="popShow">
      <div class="popwrap"></div>
      <div class="popinner scaninner scanactive1">
        <span
          @click="popShow = false"
          class="popclose"
          style="cursor: pointer; z-index: 9999"
          >X</span
        >
        <div class="scanimg">
          <img
            :src="
              require('../../assets/front/about/software/' +
                (imgIndex + 1) +
                '.jpg')
            "
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="popbox" v-if="popShow1">
      <div class="popwrap"></div>
      <div class="popinner scaninner">
        <span
          @click="popShow1 = false"
          class="popclose"
          style="cursor: pointer; z-index: 9999"
          >X</span
        >
        <div class="scanimg">
          <img
            :src="
              require('../../assets/front/about/honor/' +
                y +
                '/' +
                y +
                '-' +
                (i + 1) +
                '.jpg')
            "
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="popbox" v-if="popShow2">
      <div class="popwrap"></div>
      <div class="popinner scaninner" style="width: 550px">
        <span
          @click="popShow2 = false"
          class="popclose"
          style="cursor: pointer; z-index: 9999"
          >X</span
        >
        <div class="scanimg">
          <img
            :src="
              require('../../assets/front/about/invent/' + imgIndex + '.jpg')
            "
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 视频弹窗 -->
    <div class="videopop" v-show="show">
      <div class="videwrap"></div>
      <div class="videoinner">
        <span style="cursor: pointer; z-index: 9999" @click="show = false"
          >X</span
        >
        <video
          id="videoId"
          autoplay=""
          loop=""
          preload=""
          x5-video-player-fullscreen="true"
          muted=""
          x-webkit-airplay="true"
          airplay="allow"
          webkit-playsinline="true"
          playsinline="true"
          controls=""
          src="https://rh.2hrh.com/static/file/rhportal/video.mp4"
        ></video>
      </div>
    </div>

    <div id="bd">
      <div id="ban-in">
        <div class="headerbox">
          <div class="bannerbox">
            <h2 class="fadeInRight animated">最懂产业运营的工业服务商</h2>
            <div class="words fadeInUp animated">
              融合科技前身为中国电信的“双创”团队，2014年6月，经工商注册成为一家具有独立法人和完全市场化、掌握核心知识产权、拥有专业过硬技术的高新技术型互联网公司，是国家工信部认定的工业大数据分类分级试点单位。
              <br /><br />
              融合科技基于工业互联网、工业大数据的“园区运营智慧云平台”、“工业管理智慧云平台”、“产业服务智慧云平台”的研发、建设及运营；面向各级工业主管部门、工业企业及工业从业人员提供完整工业体系服务；助推工业领域信息互联、资源互通、产业协同配套。
              <br /><br />
              截止2023年1月，公司的工业园区智慧云平台产品覆盖了全国100多家省级以上工业园区，实现了省、市、县、园区、企业“横向互联、纵向互通”的五级联动体系。该平台也被评为“工信部2020年大数据产业发展试点示范项目”，是全国数据体量最大、服务体系最全、接入园区最集中、进驻企业最多的工业大数据应用平台。
            </div>
            <div class="videobtn fadeInUp animated" @click="show = true">
              <div class="imgBox"></div>
              了解融合科技
            </div>
          </div>
        </div>
        <!--        <div class="ban-bg"></div>-->
      </div>

      <!-- 公司介绍 -->
      <!--      <div class="companyintro">-->
      <!--        <div class="wp">-->
      <!--          <div class="intro-left">-->
      <!--            <video-->
      <!--              src="https://rh.2hrh.com/static/file/rhgw2013/index/rhxc.mp4"-->
      <!--              class="aboutvideo"-->
      <!--              playstatus="0"-->
      <!--              width="100%"-->
      <!--              controls-->
      <!--            ></video>-->
      <!--          </div>-->
      <!--          <div class="intro-right">-->
      <!--            <div class="ir-top">-->
      <!--              <h3>公司简介</h3>-->
      <!--              <div class="eng">-->
      <!--                <span>Company</span>-->
      <!--                Profile-->
      <!--              </div>-->
      <!--              <i></i>-->
      <!--            </div>-->
      <!--            <div class="ir-bottom">-->
      <!--              <p>-->
      <!--                融合科技前身为中国电信的“双创”团队，2014年6月，经工商注册成为一家具有独立法人和完全市场化、掌握核心知识产权、拥有专业过硬技术的高新技术型互联网公司，是国家工信部认定的工业大数据分类分级试点单位。-->
      <!--              </p>-->
      <!--              <p>-->
      <!--                融合科技致力于工业大数据中心，产业大数据中心以及基于工业大数据的创新应用开发，涵盖互（物）联网+产业链招商平台、精准服务安商平台、协同制造营商平台的研发、建设及运营。助推园区运营联盟化、工业管理平台化、产业发展生态化。-->
      <!--              </p>-->
      <!--              <p>-->
      <!--                截止2021年3月，公司的工业园区智慧云平台产品覆盖了全国100多家省级以上工业园区，实现了省、市、县、园区、企业“横向互联、纵向互通”的五级联动体系。该平台也被评为“工信部2020年大数据产业发展试点示范项目”，是全国数据体量最大、服务体系最全、接入园区最集中、进驻企业最多的工业大数据应用平台。-->
      <!--              </p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- 资质证书 -->
      <div class="carouselbanner certifications">
        <div class="wp">
          <div class="tit-i">
            <h3>资质证书</h3>
            <h5>Certifications</h5>
          </div>
          <!-- 切换 -->
          <ul class="tabbox">
            <li :class="{ active: tab1 === 0 }" @click="tab1 = 0">
              软件著作权证书
            </li>
            <li :class="{ active: tab1 === 1 }" @click="tab1 = 1">
              发明专利证书
            </li>
          </ul>
          <div v-show="tab1 === 0" class="carouselbox">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide v-for="(item, index) in imgName" :key="index">
                <img
                  class="swiperimg"
                  style="height: unset"
                  @click="swiperPop(index)"
                  :src="
                    require('../../assets/front/about/software/' +
                      (index + 1) +
                      '.jpg')
                  "
                  alt=""
                />
                {{ item }}
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
            <div class="carousel-btnleft">
              <img src="../../assets/front/about/leftbtngray.png" alt="" />
            </div>
            <div class="carousel-btnright">
              <img src="../../assets/front/about/rightbtngray.png" alt="" />
            </div>
          </div>
          <ul class="invent" v-show="tab1 === 1">
            <li>
              <img
                class="swiperimg"
                @click="swiperPop1(1)"
                src="../../assets/front/about/invent/1.jpg"
                alt=""
              />
              <p>基于大数据的智慧城市业务分配系统</p>
            </li>
            <li>
              <img
                class="swiperimg"
                @click="swiperPop1(2)"
                src="../../assets/front/about/invent/2.jpg"
                alt=""
              />
              <p>基于大数据的智慧安防系统数据共享方法</p>
            </li>
          </ul>
        </div>
      </div>

      <!-- 公司荣誉 -->
      <div class="enterprisehonor">
        <div class="wp">
          <div class="tit-i">
            <h3>公司荣誉</h3>
            <h5>
              Enterprise
              <span>Honor</span>
            </h5>
          </div>
          <ul class="honor-list">
            <li class="" v-for="(item, index) in honorList" :key="index">
              <div class="hl-inner">
                <div class="year">{{ item.year }}</div>
                <div class="hl-list">
                  <p
                    class="honoritems"
                    v-for="(itemc, indexc) in item.list"
                    :key="indexc"
                    @click="imgPop(item.year, indexc)"
                  >
                    <i></i>{{ itemc }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 领导关怀 -->
      <div class="carouselbanner leadership">
        <div class="wp">
          <div class="tit-i">
            <h3>领导关怀</h3>
            <h5>
              Leadership
              <span>care</span>
            </h5>
          </div>
          <div class="carouselbox">
            <swiper class="swiper" :options="swiperOption1">
              <swiper-slide v-for="(item, index) in careforItems" :key="index">
                <a href="javascript:;">
                  <img :src="item.img" alt="" />
                  <p>
                    {{ item.title }}
                  </p>
                  <span>{{ item.date }}</span>
                </a>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
            <div class="carousel-btnleft">
              <img src="../../assets/front/about/leftbtngray.png" alt="" />
            </div>
            <div class="carousel-btnright">
              <img src="../../assets/front/about/rightbtngray.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!--    <div class="company-banner">-->
      <!--      <part-title-->
      <!--        zh="公司简介"-->
      <!--        en="COMPANY PROFILE"-->
      <!--        enColor="#fff"-->
      <!--        zhColor="#fff"-->
      <!--      />-->
      <!--      <van-swipe-->
      <!--        class="companny-swipe"-->
      <!--        :autoplay="3000"-->
      <!--        indicator-color="white"-->
      <!--      >-->
      <!--        <van-swipe-item>-->
      <!--          融合科技前身为中国电信的“双创”团队，2014年6月，经工商注册成为一家具有独立法人和完全市场化、掌握核心知识产权、拥有专业过硬技术的高新技术型互联网公司，是国家工信部认定的工业大数据分类分级试点单位。-->
      <!--        </van-swipe-item>-->
      <!--        <van-swipe-item>-->
      <!--          融合科技致力于工业大数据中心，产业大数据中心以及基于工业大数据的创新应用开发，涵盖互（物）联网+产业链招商平台、精准服务安商平台、协同制造营商平台的研发、建设及运营。助推园区运营联盟化、工业管理平台化、产业发展生态化。-->
      <!--        </van-swipe-item>-->
      <!--        <van-swipe-item>-->
      <!--          截止2021年3月，公司的工业园区智慧云平台产品覆盖了全国100多家省级以上工业园区，实现了省、市、县、园区、企业“横向互联、纵向互通”的五级联动体系。该平台也被评为“工信部2020年大数据产业发展试点示范项目”，是全国数据体量最大、服务体系最全、接入园区最集中、进驻企业最多的工业大数据应用平台。-->
      <!--        </van-swipe-item>-->
      <!--      </van-swipe>-->
      <!--      <div class="video">-->
      <!--        <video-->
      <!--          src="https://rh.2hrh.com/static/file/rhgw2013/index/rhxc.mp4"-->
      <!--          class="aboutvideo"-->
      <!--          playstatus="0"-->
      <!--          width="100%"-->
      <!--          id="companyVideo"-->
      <!--          controls=""-->
      <!--        ></video>-->
      <!--      </div>-->
      <!--    </div>-->
      <!--    <div class="certifications">-->
      <!--      <part-title zh="资质证书" en="certifications" />-->
      <!--      <div class="certification-nav">-->
      <!--        <slef-nav-->
      <!--          :list="['软件著作权证书', '发明专利证书']"-->
      <!--          :active.sync="active"-->
      <!--        />-->
      <!--      </div>-->
      <!--      <div class="swiper-certification" v-show="active === 0">-->
      <!--        <van-swipe-->
      <!--          class="certification-swipe"-->
      <!--          :autoplay="3000"-->
      <!--          :show-indicators="false"-->
      <!--        >-->
      <!--          <van-swipe-item v-for="(item, index) in imgs" :key="index">-->
      <!--            <div class="img-box">-->
      <!--              <img v-for="img in item" :key="img" :src="img" alt="" />-->
      <!--            </div>-->
      <!--          </van-swipe-item>-->
      <!--        </van-swipe>-->
      <!--      </div>-->
      <!--      <div class="img-box" v-show="active === 1">-->
      <!--        <img-->
      <!--          src="https://rh.2hrh.com/static/file/rhportal/image/front/about/invent/1.jpg"-->
      <!--          alt=""-->
      <!--        />-->
      <!--        <img-->
      <!--          src="https://rh.2hrh.com/static/file/rhportal/image/front/about/invent/2.jpg"-->
      <!--          alt=""-->
      <!--        />-->
      <!--      </div>-->
      <!--    </div>-->

      <!--    <div class="honor">-->
      <!--      <part-title-->
      <!--        zh="公司荣誉"-->
      <!--        en="ENTERPRISE HONOR"-->
      <!--        enColor="#fff"-->
      <!--        zhColor="#fff"-->
      <!--      />-->
      <!--      <div class="honor-years">-->
      <!--        <div-->
      <!--          v-for="item in honors"-->
      <!--          :key="item.year"-->
      <!--          class="item"-->
      <!--          :class="{ active: item.year === year }"-->
      <!--          @click="setYear(item)"-->
      <!--        >-->
      <!--          <div class="circle"></div>-->
      <!--          <span>{{ item.year }}</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="honor-swiper-container">-->
      <!--        <div class="year-title">{{ year }}</div>-->
      <!--        <van-swipe :autoplay="3000" indicator-color="white">-->
      <!--          <van-swipe-item-->
      <!--            v-for="(img, index) in honorsImgs"-->
      <!--            :key="img"-->
      <!--            class="honor-swiper"-->
      <!--          >-->
      <!--            <div class="text">{{ honorsTexts[index] }}</div>-->
      <!--            <img :key="img" :src="img" alt="" />-->
      <!--            <div class="block"></div>-->
      <!--          </van-swipe-item>-->
      <!--        </van-swipe>-->
      <!--      </div>-->
      <!--    </div>-->

      <!--    <div class="care">-->
      <!--      <part-title zh="领导关怀" en="LEADERSHIP CARE" />-->
      <!--      <div class="care-item" v-for="item in careforItems" :key="item.date">-->
      <!--        <img :src="item.img" alt="" />-->
      <!--        <div class="title">{{ item.title }}</div>-->
      <!--        <div class="date">{{ item.date }}</div>-->
      <!--      </div>-->
      <!--    </div>-->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tab1: 0,
      swiperOption: {
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        },
        slidesPerView: 4,
        spaceBetween: 30,
        slidesPerGroup: 1,
        observer: true,
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        // },
        navigation: {
          nextEl: '.carousel-btnright',
          prevEl: '.carousel-btnleft',
        },
      },
      swiperOption1: {
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        },
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 1,
        observer: true,
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        // },
        navigation: {
          nextEl: '.carousel-btnright',
          prevEl: '.carousel-btnleft',
        },
      },
      imgName: [
        '赣企服V5.0',
        'EIT工具系统V1.0',
        'GIS系统软件V1.0',
        '产品展示展销系统V2.0',
        '产融合作信息服务平台V1.0',
        '产业集群电子地图系统V1.0',
        '城市大数据采集平台V1.0',
        '大数据中心系统V2.0',
        '产业集群台账系统V2.0',
        '党建管理系统V2.0',
        '工业互联网大数据平台V1.0',
        '江西省工业园区智慧云平台系统V2.0',
        '经验交流系统V1.0',
        '考核管理系统V1.0',
        '企业精准帮扶系统V1.0',
        '企业台账系统V1.0',
        '视频监控系统V1.0',
        '数据上报系统V1.0',
        '数据质量管控工具系统V1.0',
        '网格管理系统V1.0',
        '文件下达系统V1.0',
        '物业管理系统V2.0',
        '项目申报系统V1.0',
        '宜春市智慧工业平台系统V2.0',
        '元数据管理工具系统V1.0',
        '园区服务大屏公示系统V1.0',
        '园区台账系统V2.0',
        '园区自助终端服务系统V1.0',
      ],
      show: false,
      popShow: false,
      popShow1: false,
      popShow2: false,
      y: '',
      i: '',
      imgIndex: '',
      imgSrc: '',
      honorList: [
        {
          year: '2022',
          list: [
            '2022年度国家知识产权优势企业',
            '江西青年创业就业基金会副理事长单位',
            '工业大数据分析与集成应用工业和信息化部重点实验室工作组成员单位',
            '2022年省级信息化和工业化融合示范企业',
            '2022年省级工业互联网重点培育平台',
            '2022年江西省中小企业公共服务示范平台',
          ],
        },
        {
          year: '2021',
          list: [
            '国家工信部第三批服务型制造示范平台',
            '国家工信部工业互联网APP优秀解决方案名单',
            '江西省“5G+工业互联网”应用试点示范项目',
            '2021年度江西省互联网企业20强',
            '江西省科技型中小企业',
            '南昌市中小企业公共服务示范平台',
          ],
        },
        {
          year: '2020',
          list: [
            '国家级高新技术企业',
            '工信部大数据产业发展试点示范项目',
            '工信部工业大数据分类分级应用试点优秀案例',
            '江西省管理创新示范企业',
            '江西省第一批大数据试点示范企业',
          ],
        },
        {
          year: '2019',
          list: [
            '江西省“互联网企业20强”',
            '江西省“专精特新中小企业”',
            '江西省电子信息行业“优秀IT企业”',
          ],
        },
        {
          year: '2018',
          list: ['中国优选品牌'],
        },
        {
          year: '2017',
          list: ['国家高新技术企业', '中国计算机用户应用项目创新奖'],
        },
        {
          year: '2016',
          list: ['荣获江西省“重大科技项目奖”'],
        },
      ],

      careforItems: [
        {
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/about/care8.jpg',
          title:
            '2021年2月江西省财政厅厅长朱斌现场调研宜春市大数据产业指挥中心。',
          date: '2021年2月',
        },
        {
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/about/care7.jpg',
          title: '江西省科技厅副厅长陈金桥莅临融合科技进行各项考察。',
          date: '2020年11月',
        },
        // {
        //   img: 'https://rh.2hrh.com/static/file/rhportal/image/front/about/care6.jpg',
        //   title:
        //     '江西省省政府领导、原省工信厅厅长杨贵平等亲手启动“江西省汽车产业智慧云平台”上线。',
        //   date: '2020年9月',
        // },
        {
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/about/care0.jpg',
          title:
            '原江西省省委书记易炼红参观省级工业互联网运营中心和井冈山经开区工业大数据指挥中心。',
          date: '2019年11月',
        },
        {
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/about/care5.jpg',
          title:
            '原省工信厅厅长杨贵平指定“江西省工业园区智慧云平台”作为江西省唯一信息化援疆产品。',
          date: '2019年6月',
        },
        {
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/about/care4.jpg',
          title:
            '原吉安市委书记，现江西省人大常委会副主任胡世忠考察井开区大数据中心。',
          date: '2019年2月',
        },
        {
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/about/care3.jpg',
          title:
            '江西省政府工作报告，原江西省省委书记易炼红强调：“强化开发区平台支撑,开展智慧开发区创建，确保年底前所有开发区（园区）智慧平台与区内规模以上企业互联互通，实现园区事园区内办结。”',
          date: '2019年1月27日',
        },
        {
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/about/care2.jpg',
          title: '江西省、宜春市领导考察宜春工业大数据云平台。',
          date: '2018年5月',
        },
        {
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/about/care1.jpg',
          title:
            '江西省工信厅副厅长辛清华主持江西省工业园区智慧云平台上线仪式。',
          date: '2018年1月',
        },
      ],
      year: '2020',

      refresh: true,
      honorsImgs: [],
      honorsTexts: [],
      honors: [
        {
          year: '2020',
          imgs: [
            'https://rh.2hrh.com/static/file/rhportal/image/front/about/honor/2020/2020-1.jpg',
            'https://rh.2hrh.com/static/file/rhportal/image/front/about/honor/2020/2020-2.jpg',
            'https://rh.2hrh.com/static/file/rhportal/image/front/about/honor/2020/2020-3.jpg',
            'https://rh.2hrh.com/static/file/rhportal/image/front/about/honor/2020/2020-4.jpg',
            'https://rh.2hrh.com/static/file/rhportal/image/front/about/honor/2020/2020-5.jpg',
          ],
          texts: [
            '国家级高新技术企业',
            '工信部大数据产业发展试点示范项目',
            '工信部工业大数据分类分级应用试点优秀案例',
            '江西省管理创新示范企业',
            '江西省第一批大数据试点示范企业',
          ],
        },
        {
          year: '2019',
          imgs: [
            'https://rh.2hrh.com/static/file/rhportal/image/front/about/honor/2019/2019-1.jpg',
            'https://rh.2hrh.com/static/file/rhportal/image/front/about/honor/2019/2019-2.jpg',
            'https://rh.2hrh.com/static/file/rhportal/image/front/about/honor/2019/2019-3.jpg',
          ],
          texts: [
            '江西省“互联网企业20强”',
            '江西省“专精特新中小企业”',
            '江西省电子信息行业“优秀IT企业”',
          ],
        },
        {
          year: '2018',
          imgs: [
            'https://rh.2hrh.com/static/file/rhportal/image/front/about/honor/2018/2018-1.jpg',
          ],
          texts: ['中国优选品牌'],
        },
        {
          year: '2017',
          imgs: [
            'https://rh.2hrh.com/static/file/rhportal/image/front/about/honor/2017/2017-1.jpg',
            'https://rh.2hrh.com/static/file/rhportal/image/front/about/honor/2017/2017-2.jpg',
          ],
          texts: ['国家高新技术企业', '中国计算机用户应用项目创新奖'],
        },
        {
          year: '2016',
          imgs: [
            'https://rh.2hrh.com/static/file/rhportal/image/front/about/honor/2016/2016-1.jpg',
          ],
          texts: ['荣获江西省“重大科技项目奖”'],
        },
      ],
      active: 0,
      imgs: [
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/25.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/26.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/27.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/28.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/1.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/2.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/3.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/4.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/5.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/6.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/7.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/8.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/9.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/10.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/11.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/12.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/13.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/14.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/15.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/16.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/17.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/18.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/19.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/20.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/21.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/22.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/23.jpg',
        'https://rh.2hrh.com/static/file/rhportal/image/front/about/software/24.jpg',
      ],
    };
  },
  methods: {
    swiperPop(e) {
      this.imgIndex = e;
      this.popShow = true;
    },
    swiperPop1(e) {
      this.imgIndex = e;
      this.popShow2 = true;
    },
    imgPop(y, i) {
      this.y = y;
      this.i = i;
      this.popShow1 = true;
    },
    setYear(item) {
      this.honorsImgs = item.imgs;
      this.honorsTexts = item.texts;
      this.year = item.year;
    },
    setActive(active) {
      this.active = active;
    },
    getActive(index) {
      return index === this.active ? 'active' : '';
    },
  },
  created() {
    this.setYear(this.honors[0]);
    this.imgs = this.imgs.reduce(
      (pre, next) => {
        const last = pre[pre.length - 1];
        if (last.length === 0) {
          pre[pre.length - 1][0] = next;
          return pre;
        }
        if (last.length === 1) {
          pre[pre.length - 1][1] = next;
          return pre;
        }
        if (last.length === 2) {
          pre = [...pre, [next]];
          return pre;
        }
      },
      [[]],
    );
  },
};
</script>
<style lang="scss" scoped>
#bd {
}
.videopop {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  transition: all 0.3s;
}
.videopop .videwrap {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.videopop .videoinner {
  position: absolute;
  width: 85%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 5px;
}
.videopop .videoinner span {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  font: 20px/40px 'microsoft yahei';
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
}
.videopop .videoinner video {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: fill;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.headerbox {
  width: 100%;
  height: 750px;
  overflow: hidden;
  position: relative;
}
.headerbox video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.headerbox .video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.bannerbox {
  position: absolute;
  top: 90px;
  width: 100%;
  height: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}
.bannerbox h2 {
  font-size: 40px;
  color: #fff;
  letter-spacing: 10px;
  margin: 0;
  animation-name: fadeInOP;
  animation: fadeInOP 2s linear;
}
.bannerbox .words {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  font-size: 16px;
  color: #fff;
  margin-top: 20px;
  letter-spacing: 3px;
  padding: 0 454px;
  padding-top: 40px;
  line-height: 30px;
  font-weight: 100;
  animation-name: fadeInOP;
  animation: fadeInOP 2s linear;
  font-family: PingFang SC, PingFang SC;
  font-weight: 300;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInOP {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bannerbox .words span {
  color: #fff000;
}
.bannerbox .videobtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  width: 220px;
  height: 44px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  color: #fff;
  font: 20px/44px 'microsoft yahei';
  margin-top: 80px;
  letter-spacing: 3px;
  cursor: pointer;
  .imgBox {
    height: 22px;
    width: 22px;
    margin-right: 10px;
    background: url('../../assets/front/headernew/videobtn.png');
  }
  animation-name: fadeInOP;
  animation: fadeInOP 1s linear;
}
.bannerbox .videobtn:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  width: 220px;
  height: 44px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  color: #333333;
  background: #ffffff;
  font: 20px/44px 'microsoft yahei';
  margin-top: 80px;
  letter-spacing: 3px;
  cursor: pointer;
  .imgBox {
    height: 22px;
    width: 22px;
    margin-right: 10px;
    background: url('../../assets/front/headernew/videobtn_black.png');
    background-size: 100% 100%;
  }
}
.bannerbox .videobtn img {
  margin-right: 10px;
}
#ban-in {
  position: relative;
  background-position: center 0;
  background-repeat: no-repeat;
  /*padding-top: 16%;*/
  background-size: 100% 100%;
  height: 750px;
  background: url(../../assets/front/about/aboutbanner.png) center center
    no-repeat;
}
#ban-in .ban-bg {
  /* background: url(../../image/front/ban_bg.png) center top no-repeat; */
  height: 54px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: 60;
}
.pages {
  text-align: center;
  margin-bottom: 105px;
  margin-top: 20px;
}
.pages a {
  display: inline-block;
  width: 41px;
  height: 41px;
  background-color: #edecec;
  border-radius: 3px;
  text-align: center;
  line-height: 41px;
  font-family: Arial;
  font-size: 18px;
  color: #666;
  margin: 0 4px;
}
.pages a:hover,
.pages .page-on {
  background-color: #1071b5;
  color: #fff;
}
/* 公司简介 */
.companyintro .wp {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  height: 480px;
  padding: 40px 0;
  font-family: 'PingFang SC';
}
.companyintro .intro-left {
  width: 700px;
  height: 400px;
  position: relative;
  overflow: hidden;
}
/* .companyintro .intro-left video{width: 700px;height: 400px;cursor:pointer;} */
.companyintro .intro-left .videoimg {
  width: 700px;
  height: 400px;
}
/* .companyintro .intro-left img{width: 80px;height: 80px;position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);} */
.companyintro .intro-right {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
  padding-left: 20px;
}
.companyintro .intro-right .ir-top {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: flex-end;
}
.companyintro .intro-right .ir-top i {
  position: absolute;
  top: 60px;
  left: 0;
  width: 45px;
  height: 3px;
  background-color: #1f73ff;
}
.companyintro .intro-right .ir-top h3 {
  font-size: 30px;
  color: #333;
  font-family: 'PingFang SC';
}
.companyintro .intro-right .ir-top .eng {
  font-size: 20px;
  color: #999;
  margin-left: 13px;
}
.companyintro .intro-right .ir-top .eng span {
  color: #1f73ff;
}
.companyintro .intro-right .ir-bottom {
  font: 14px/24px 'PingFang SC';
  color: #333;
  margin-top: 38px;
  p {
    margin: 0;
  }
}
.companyintro .intro-right .ir-bottom p {
  text-indent: 2em;
}
/* 资质证书 */
.certifications {
  padding-bottom: 50px;
}
.certifications .swiper-slide {
  padding-top: 10px;
  height: 396px;
}
.certifications .swiper-slide img {
  height: 356px;
}
.certifications .invent {
  padding: 10px 0 20px;
  height: 416px;
  //display: none;
  text-align: center;
}
.certifications .invent li {
  width: 288px;
  height: 406px;
  margin-right: 30px;
  display: inline-block;
  vertical-align: middle;
}
.certifications .invent li:hover img {
  box-shadow: 0 0 12px rgba(153, 153, 153, 0.5);
}
.certifications .invent li:last-of-type {
  margin-right: 0;
}
.certifications .invent li img {
  display: block;
  width: 100%;
  height: 376px;
  transition: all 0.3s;
  cursor: pointer;
}
.certifications .invent li p {
  text-align: center;
  font: 16px/30px 'PingFang SC';
  color: #666;
  margin-top: 10px;
}
.certifications .swiper-slide:hover {
  box-shadow: 0px 0 0 rgba(153, 153, 153, 0.3);
}
.certifications .swiper-slide img:hover {
  box-shadow: 0 0 12px rgba(153, 153, 153, 0.5);
}
.certifications .swiper-slide p {
  text-align: center;
  font: 16px/30px 'PingFang SC';
  color: #666;
  padding: 0;
  margin-top: 10px;
}
.certifications .carousel-btnleft,
.certifications .carousel-btnright {
  transform: translateY(-75%);
}
/* 共用切换 */
.tabbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  margin-bottom: 30px;
}
.tabbox li {
  position: relative;
  font: 20px/28px 'PingFang SC';
  color: #333;
  cursor: pointer;
}
.tabbox li:before {
  content: '';
  position: absolute;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: transparent;
}
.tabbox li.active,
.tabbox li:hover {
  color: #1f73ff;
}
.tabbox li.active:before,
.tabbox li:hover:before {
  background-color: #1f73ff;
}
.tabbox li:nth-of-type(1) {
  margin-right: 180px;
}
/* 轮播图 */
.carouselbanner {
  padding-bottom: 30px;
}
.carouselbanner .tit-i {
  font-family: 'PingFang SC';
}
.carouselbanner .tit-i h5 {
  font-family: 'PingFang SC';
}
.carouselbanner .tit-i {
  padding: 50px 0 30px;
}
.carouselbanner .swiper-container {
  padding: 0 5px 20px;
}
.carouselbanner .swiper-slide {
  height: 356px;
  transition: all 0.3s;
}
.carouselbanner .swiper-slide a {
  cursor: default;
}
.carouselbanner .swiper-slide:hover {
  box-shadow: 0px 3px 12px rgba(153, 153, 153, 0.6);
}
.carouselbanner .swiper-slide img {
  height: 240px;
  width: 100%;
  display: block;
  cursor: pointer;
}
.carouselbanner .swiper-slide p {
  font: 16px/22px 'PingFang SC';
  color: #333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 5px 10px 0;
}
.carouselbanner .swiper-slide span {
  display: block;
  font: 14px/26px 'PingFang SC';
  color: #999;
  margin-top: 7px;
  padding: 0 10px;
}
.carouselbanner .swiper-button-prev,
.carouselbanner .swiper-button-next {
  width: 0;
  height: 0;
  transition: all 0.3s;
}
.carouselbanner .swiper-button-prev img,
.carouselbanner .swiper-button-next img {
  width: 0;
  height: 0;
  opacity: 0.7;
}
.carouselbanner .swiper-button-prev img:hover,
.carouselbanner .swiper-button-next img:hover {
  opacity: 1;
}

.carouselbox {
  position: relative;
}
.carouselbox .carousel-btnleft,
.carouselbox .carousel-btnright {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.5;
}
.carouselbox .carousel-btnleft:hover,
.carouselbox .carousel-btnright:hover {
  opacity: 1;
}
.carouselbox .carousel-btnleft {
  left: -65px;
}
.carouselbox .carousel-btnright {
  right: -65px;
}
.carouselbox .carousel-btnleft img,
.carouselbox .carousel-btnright img {
  width: 50px;
  height: 50px;
}
/* 公用弹窗 */
/* transition: all .3s; */
.popbox {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: block;
}
.popbox .popwrap {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.popbox .popinner {
  position: absolute;
  width: 50%;
  max-height: 80vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 5px;
}
.popbox .popinner .popclose {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  font: 20px/40px 'microsoft yahei';
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
}
.popbox .popContent {
  width: 100%;
  max-height: 80vh;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}
.popbox .popContent .pc-title {
  text-align: center;
  margin-bottom: 20px;
}
.popbox .popContent .pc-title H3 {
  font: 28px/35px 'FZCuHeiSongS-B-GB';
}
.popbox .popContent .pc-title p {
  font: 13px/20px 'PingFang SC';
  color: #999;
}
.popbox .popContent ul {
  width: 100%;
}
.popbox .popContent::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 12px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.popbox .popContent::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  background: #999;
}
.popbox .popContent::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
.popbox .popContent li {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  flex: 1;
  margin-bottom: 25px;
}
.popbox .popContent li:last-of-type {
  margin-bottom: 0;
}
.popbox .popContent li img {
  width: 272px;
  height: 175px;
}
.popbox .popContent li .content-right {
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
  flex: 1;
  padding-left: 20px;
}
.popbox .popContent li .content-right h3 {
  font: 600 19px/30px 'PingFang SC';
  color: #333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 15px;
}
.popbox .popContent li .content-right p {
  font: 15px/27px 'PingFang SC';
  color: #666;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
}
.popbox .scanactive1 {
  width: 700px;
}
.popbox .scanactive1 .scanimg img {
  width: 550px;
}
.popbox .scaninner {
  max-height: 90vh;
}
.popbox .scaninner .scanimg {
  width: 100%;
  max-height: 90vh;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}
.popbox .scanimg img {
  max-width: 100%;
  max-height: 80vh;
  display: block;
  min-height: 150px;
  margin: 0 auto;
}
/* 公司荣誉 */
.enterprisehonor {
  width: 100%;
  height: 1470px;
  background-color: #eee;
  background: url(../../assets/front/about/honorbg.png) center center repeat;
  background-size: 100% 100%;
  font-family: 'PingFang SC';
}
.enterprisehonor .tit-i {
  font-family: 'PingFang SC';
}
.enterprisehonor .tit-i h5 {
  font-family: 'PingFang SC';
}
.enterprisehonor .honor-list {
}
.enterprisehonor .honor-list li {
  display: flex;
  justify-content: flex-end;
  flex-flow: row nowrap;
  cursor: pointer;
}
.enterprisehonor .honor-list li:nth-of-type(even) {
  justify-content: flex-start;
}
.enterprisehonor .honor-list .hl-inner {
  width: 50%;
  padding-top: 15px;
  box-sizing: border-box;
}
.enterprisehonor .honor-list li:nth-of-type(1) .hl-inner {
  padding-top: 0;
}
.enterprisehonor .honor-list li:nth-of-type(odd) .hl-inner {
  border-left: 1px solid #d1d1d1;
  padding-left: 20px;
}
.enterprisehonor .honor-list li:nth-of-type(even) .hl-inner {
  width: calc(50% + 1px);
  display: flex;
  justify-content: flex-end;
  flex-flow: column nowrap;
  align-items: flex-end;
  border-right: 1px solid #d1d1d1;
  padding-right: 20px;
}
.enterprisehonor .honor-list .hl-inner .year {
  position: relative;
  font: 400 30px/35px 'DIN-BoldItalic';
  color: #999999;
  text-shadow: 0px 3px 6px rgba(153, 153, 153, 0.3);
  font-weight: 400;
}
.enterprisehonor .honor-list li:nth-of-type(even) .hl-inner .year {
  text-align: right;
}
.enterprisehonor .honor-list .hl-inner .year::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #666;
  border: 4px solid #fff;
  box-shadow: 0px 3px 10px rgba(153, 153, 153, 0.3);
}
.enterprisehonor .honor-list li:first-of-type .hl-inner .year::after {
  content: '';
  position: absolute;
  top: 0;
  left: -21px;
  width: 2px;
  height: 8px;
  background-color: #f2f2f2;
}
.enterprisehonor .honor-list li:last-of-type .hl-inner .year::after {
  content: '';
  position: absolute;
  top: 26px;
  left: -21px;
  width: 2px;
  height: 77px;
  background-color: #f2f2f2;
}
.enterprisehonor .honor-list li:nth-of-type(odd) .hl-inner .year::before {
  left: -29px;
}
.enterprisehonor .honor-list li:nth-of-type(even) .hl-inner .year::before {
  right: -30px;
}
.honor-list .hl-inner .hl-list {
  width: 450px;
  padding: 15px;
  background-color: #fff;
  margin-top: 10px;
}
.honor-list .hl-inner .hl-list p {
  width: 100%;
  font: 14px/27px 'PingFang SC';
  color: #999;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 27px;
  margin: 0;
}
/* overflow: hidden; */
.honor-list .hl-inner .hl-list p i {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #999;
  border-radius: 50%;
  opacity: 1;
  margin-right: 8px;
  vertical-align: middle;
}
.enterprisehonor li .hl-inner:hover .year {
  color: #ff2d58;
  text-shadow: 0px 3px 6px rgba(255, 45, 88, 0.3);
}
.enterprisehonor .tit-i {
  padding: 40px 0 30px;
}
.enterprisehonor li .hl-inner:hover .year::before {
  background-color: #ff2d58;
}
.enterprisehonor li .hl-inner .hl-list p:hover {
  color: #333;
}
.enterprisehonor li .hl-inner .hl-list p:hover i {
  background-color: #ff2d58;
}

.enterprisehonor li.active .hl-inner .year {
  color: #ff2d58;
  text-shadow: 0px 3px 6px rgba(255, 45, 88, 0.3);
}
.enterprisehonor li.active .hl-inner .year::before {
  background-color: #ff2d58;
}
/* 领导关怀 */
.leadership .carouselbox .carousel-btnleft,
.leadership .carouselbox .carousel-btnright {
  top: 27%;
  transform: translateY(0);
}
@-webkit-keyframes fadeInDown1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInDown1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.carouselbox,
.invent {
  animation: fadeInDown1 1s linear;
}
</style>
